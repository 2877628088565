import React from "react";
import Iframe from 'react-iframe'
import BackNavigation from "../../components/backNavigation/backNavigation";
import Services from "../../services/Services";
import BottomBarMenu from "../../layout/general/BottomBarMenu";
import TranslateData from "../../services/TranslateData";
import ReactLoading from "react-loading";
import refreshIc from "../../assets/icons/refresh-ic.svg";
import { EmailIcon, EmailShareButton, TelegramIcon, TelegramShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";


function DocumentPreview() {


    const url = window.location.href.split(window.location.origin+'/DocumentPreview/?url=');

        
    return(
        <>

            <BackNavigation title={TranslateData.Word("languageShort")[0]["back"]} />

            <div style={{width:"100%", 
                height:"100%", 
                position: "fixed", 
                top:0, 
                right:0, 
                left:0, 
                bottom:0, 
                backgroundColor:'#fff', 
                display:'flex', 
                justifyContent:'center', 
                alignItems:'center' }}>
                    <ReactLoading type="bubbles" color={"#C00020"} />
                </div> 
                

                <WhatsappShareButton
                        className="documentRefresh"
                        url={(Services.apiServerUrl("languageShort")+url).replace('/,', '')}
                        title="Akfix"
                    >
                    <WhatsappIcon size={37} round={true} />
                </WhatsappShareButton>           

                <TelegramShareButton
                    style={{marginBottom:'50px'}}
                    className="documentRefresh"
                    url={(Services.apiServerUrl("languageShort")+url).replace('/,', '')}
                    title="Akfix"
                    >
                    <TelegramIcon size={37} round={true} />
                </TelegramShareButton>


               
                <a href={""} className="documentRefresh" style={{marginBottom:'100px'}}>
                    <img src={refreshIc} alt="" />
                </a>
               
                        

                <Iframe 
                    id="ifamePdf"
                    type="application/pdf"
                    allow="fullscreen"
                    src={"https://docs.google.com/gview?url="+(Services.apiServerUrl("languageShort")+url).replace('/,', '')+"&embedded=true"}
                    className="documentPreviewFile" />

            <BottomBarMenu />

        </>
    );
}





export default DocumentPreview;