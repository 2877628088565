function En(){
    return(
        [{

            // Menu
            "home":"HOME",
            "products":"PRODUCTS",
            "categories":"CATEGORIES",
            "about":"AKFİX",
            "videos":"VIDEOS",
            "catalogues":"CATALOGUES",
            "brochures":"BROCHURES",
            "certificates":"CERTIFICATES",
            "galleries":"GALLERIES",
            "blog":"BLOG",
            "contact":"CONTACT",

            // Home Translate
            "home_product" : "Product",
            "home_categories" : "Categories",
            "home_solutions" : "Solutions",
            "home_videos" : "Videos",
            "home_new_products" : "New Products",
            "home_blog" : "Blog",

            // General Translate
            "languages": "Languages",
            "search": "Search",
            "search_result": "Search Result",
            "back": "Back",
            "file_uploading": "File Uploading...",
            

            // Product Detail Translate
            "properties" : "Properties",
            "application_areas" : "Application Areas",
            "packaging" : "Packaging",
            "product_videos" : "Videos",
            "product_galleries" : "Galleries",
            "product_brochures" : "Brochures",
            "stock_code" : "Stock Code",
            "product_code" : "Product Code",
            "type" : "Type",
            "volume" : "Volume",
            "boxqty" : "BoxQty",

            

        }]
        
    )
}


export default En;