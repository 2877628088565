import React from "react";
import Drawer from "react-modern-drawer";
import 'react-modern-drawer/dist/index.css'
import { FaGlobe, FaBars, FaSearch, FaArrowRight } from "react-icons/fa";
import logo from "../../assets/images/logos/akfix.svg";
import { Link } from "react-router-dom";
import DrawerMenu from "../../components/drawerMenu/DrawerMenu";
import Services from "../../services/Services";
import TranslateData from "../../services/TranslateData";


function AppBar(){


    const [isDrawer, setIsDrawer] = React.useState(false)
    const [isLanguageDrawer, setIsLanguageDrawer] = React.useState(false)

    const toggleDrawer = () => {
        setIsDrawer((prevState) => !prevState)
    }

    const toggleLangugeDrawer = () => {
        setIsLanguageDrawer((prevState) => !prevState)
    }


    return(

        <>

      <DrawerMenu open={isDrawer} onClose={toggleDrawer} />

            <Drawer
                open={isLanguageDrawer}
                onClose={toggleLangugeDrawer}
                direction='right'
                className='drawerLanguageContainer'
                zIndex={'999999'}>
                <div className="drawerLanguageContent">
                    <strong className="m-20 font-size-24 font-bold text-white">{TranslateData.Word("languageShort")[0]["languages"]}</strong>
                    <div className="drawerLanguageLine"></div>

                    <div className="display-flex flex-column">
                        <DrawerLanguageItems title={"Türkçe"} shortTitle={"TR"} url={"/"} languageID={1} languageName={"tr"} />
                        <DrawerLanguageItems title={"English"} shortTitle={"EN"} url={"/"} languageID={2} languageName={"en"} />
                        <DrawerLanguageItems title={"Spanish"} shortTitle={"ES"} url={"/"} languageID={4} languageName={"es"} />
                        <DrawerLanguageItems title={"French"} shortTitle={"FR"} url={"/"} languageID={5} languageName={"fr"} />
                        <DrawerLanguageItems title={"Bulgarian"} shortTitle={"BG"} url={"/"} languageID={2} languageName={"bg"} />
                        <DrawerLanguageItems title={"Russian"} shortTitle={"RU"} url={"/"} languageID={2} languageName={"ru"} />
                        <DrawerLanguageItems title={"Italian"} shortTitle={"IT"} url={"/"} languageID={2} languageName={"it"} />
                        <DrawerLanguageItems title={"German"} shortTitle={"DE"} url={"/"} languageID={2} languageName={"de"} />
                        <DrawerLanguageItems title={"Polish"} shortTitle={"PL"} url={"/"} languageID={2} languageName={"pl"} />
                        <DrawerLanguageItems title={"Serbian"} shortTitle={"RS"} url={"/"} languageID={2} languageName={"rs"} />
                        <DrawerLanguageItems title={"USA"} shortTitle={"USA"} url={"/"} languageID={2} languageName={"usa"} />

                        {
                            /*

                                <DrawerLanguageItems title={"Bulgarian"} shortTitle={"BG"} url={""} languageID={2} languageName={"bg"} />
                                <DrawerLanguageItems title={"Ukranie"} shortTitle={"UA"} url={""} />
                                <DrawerLanguageItems title={"German"} shortTitle={"GR"} url={""} />
                                <DrawerLanguageItems title={"Persian"} shortTitle={"AF"} url={""} />
                                <DrawerLanguageItems title={"Italiano"} shortTitle={"IT"} url={""} />
                                <DrawerLanguageItems title={"Romanian"} shortTitle={"RO"} url={""} />


                                <a href={"/"} onClick={() => Services.sessionAdd("language", 1)}>
                                    <div className="drawerLanguageItems display-flex justify-content-between align-items-center">
                                        <div className="display-flex justify-content-left align-items-center">
                                            <div className="drawerLanguageShort">
                                                <strong>{"TR"}</strong>
                                            </div>
                                            <strong className="text-white font-size-16 font-bold">{"Türkçe"}</strong>
                                        </div>
                                        
                                        <FaArrowRight fontSize={12} color="#00C7AF" />

                                    </div>
                                </a>

                            */

                        }

                        <div className="drawerLanguageListLine"></div>


                    </div>
           
                </div>
            </Drawer>
                


            <div className="appBar width-100 display-flex justify-content-between align-items-center p-10">
                <div className="display-flex">
                    <Link onClick={toggleDrawer} className="mainMenuIcon mr-10">
                        <FaBars fontSize={20} className="text-black" />
                    </Link>

                    <Link to={"/"}>
                        <img src={logo} alt={""} />
                    </Link>
                </div>

                <div className="display-flex">
                    <a href={"/Search"} className="LanguageMenuIcon mr-10">
                        <FaSearch fontSize={20} className="text-black" />
                    </a>

                    <Link onClick={toggleLangugeDrawer} className="LanguageMenuIcon">
                        <FaGlobe fontSize={20} className="text-black" />
                    </Link>
                </div>

            </div>
        </>
    );
}




function DrawerLanguageItems({title, shortTitle, url, languageName, languageID}){
    return(
        <>
            <a href={url} onClick={() => [Services.sessionAdd("language", languageID), Services.sessionAdd("languageShort", languageName)]}>
                <div className="drawerLanguageItems display-flex justify-content-between align-items-center">
                    <div className="display-flex justify-content-left align-items-center">
                        <div className="drawerLanguageShort">
                            <strong>{shortTitle}</strong>
                        </div>
                        <strong className="text-white font-size-16 font-bold">{title}</strong>
                    </div>
                    
                    <FaArrowRight fontSize={12} color="#00C7AF" />

                </div>
            </a>

            <div className="drawerLanguageListLine"></div>
        </>
    );
}



export default AppBar;