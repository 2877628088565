import React from "react";
import BackNavigation from "../../components/backNavigation/backNavigation";
import BottomBarMenu from "../../layout/general/BottomBarMenu";
import MainVideoItems from "../../layout/main/MainVideoItems";
import {SlideshowLightbox} from 'lightbox.js-react';
import 'lightbox.js-react/dist/index.css';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/css/modal-video.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import closeIc from "../../assets/icons/close.svg";
import CataloguesBrochuresItems from "../../layout/media/CataloguesBrochuresItems";
import { Link, useParams } from "react-router-dom";
import Services from "../../services/Services";
import Loading from "../../components/loading/Loading";
import parse from "html-react-parser";
import TranslateData from "../../services/TranslateData";
import imageLists from "../../contants/imageLists";


function ProductDetail(){

    const params = useParams();

    const [post, setPost] = React.useState([]);
    const [visible, setVisible] = React.useState(true);

    const [isOpenVideoModal, setIsOpenVideoModal] = React.useState(false);
    const [videoUrl, setVideoUrl] = React.useState('');

    const [isOpenProductModal, setIsOpenProductModal] = React.useState(false);
    const [productUrl, setProductUrl] = React.useState('');


    const productDetailData = async () => {
        try {
            await Services.getList(Services.apiServerUrl("languageShort")+'api/data/products/detail/?url='+[params.url]).then((response) =>  {
                setPost(response.data);
                setVisible(false);
                });
        } catch (error) {
            console.log(error.message);
        }
    }

    
    React.useEffect(() => {
        productDetailData();
    }, [params.url]);


    return(
        visible ? <Loading /> :
        post.map((post, i) => 
            <>

            <BackNavigation title={post.title} bgColor={'255, 255, 255, 1'} />

            <Modal open={isOpenProductModal} onClose={setIsOpenProductModal} showCloseIcon={false}>
                <div className="modalClose" onClick={() => setIsOpenProductModal(false)}>
                    <img src={closeIc} alt="" />
                </div>

                 <img src={productUrl} alt=""  />
            </Modal>

            <div className="p-10 pt-50">

            <Carousel autoPlay={true} showIndicators={true} showArrows={true} emulateTouch={true} showThumbs={false} showStatus={false} infiniteLoop={true}>

                {
                    post.product_images == null ? <Loading /> : 
                    post.product_images.map((item) => 
                        <div className="productDetailImg" onClick={() => [setIsOpenProductModal(true), setProductUrl(item.img ? Services.apiServerUrl("languageShort")+item.img.replace("/small", "") : imageLists.productDetailNotFound)]}>
                            <img src={item.img ? Services.apiServerUrl("languageShort")+item.img : imageLists.productDetailNotFound} alt="" />

                        </div>
                    )
                }

                {
                    post.galleries == null ? <Loading /> : 
                    post.galleries.map((item) => 
                        <div className="productDetailApplicationImg" onClick={() => [setIsOpenProductModal(true), setProductUrl(item ? Services.apiServerUrl("languageShort")+item : imageLists.productDetailNotFound)]}>
                            <img src={item ? Services.apiServerUrl("languageShort")+item : imageLists.productDetailNotFound} alt="" />
                        </div>
                    )
                }
                
            </Carousel>

                { /* Product TDS - SDS - SHARE */ }
                <div className="display-flex justify-content-between align-items-center mt-20">
                    <div className="productDocumentContainer display-flex">
                        
                        { 
                            post.tds.map((item) => {
                                return(
                                    <Link 
                                        to={"/DocumentPreview/?url="+item}
                                        rel={"noreferrer"}>
                                            <div className="productDocumentTds mr-10">
                                                TDS
                                            </div>
                                    </Link>
                                );
                            })
                        }


                        {
                            post.sds.map((item) => {
                                return(
                                    <Link 
                                    to={"/DocumentPreview/?url="+item}
                                    rel={"noreferrer"}>
                                        <div className="productDocumentSds mr-10">
                                            SDS
                                        </div>
                                    </Link>
                                );
                            })
                        }

                    </div>

                    {
                        /*
                        <div className="productShareContainer display-flex align-items-center">
                            <span className="mr-10 text-gray font-size-12 text-gray">Share / </span>
                            <a href={"/ProductDetail"}>
                                <div className="whatsAppIcon">
                                    <FaWhatsapp />
                                </div>
                            </a>
                        </div>
                        */
                    }    
                    
                </div>


                <div className="productDescription mt-40">
                    {parse(post.more_information)}
                </div>


                <div className="ProductProperties-ApplicationAreas">
                    <ProductDetailAreaTitle title={TranslateData.Word("languageShort")[0]["properties"]} />
                    {parse(post.properties)}
                </div>


                <div className="ProductProperties-ApplicationAreas">
                <ProductDetailAreaTitle title={TranslateData.Word("languageShort")[0]["application_areas"]} />
                 {parse(post.application_areas)}
                </div>



                { post.packages === "" ? "" :  
                    <div className="packagingContainer">
                        <ProductDetailAreaTitle title={TranslateData.Word("languageShort")[0]["packaging"]} />
                        <table className="packagingTable">
                            <tr>
                                <th>{TranslateData.Word("languageShort")[0]["product_code"]}</th>
                                <th>{TranslateData.Word("languageShort")[0]["type"]}</th>
                                <th>{TranslateData.Word("languageShort")[0]["volume"]}</th>
                                <th>{TranslateData.Word("languageShort")[0]["boxqty"]}</th>
                            </tr>
                            {parse(post.packages)}
                        
                        </table>
                    </div>
                }


                { post.videos == "" ? "" :  
                    <div className="display-flex flex-column align-items-center">
                        <ProductDetailAreaTitle title={TranslateData.Word("languageShort")[0]["product_videos"]} />
                        <div className="display-flex justify-content-center flex-wrap">

                            <ModalVideo
                                channel="youtube"
                                youtube={{ mute: 1, autoplay: 1 }}
                                isOpen={isOpenVideoModal}
                                videoId={videoUrl}
                                onClose={() => setIsOpenVideoModal(false)}
                            />


                            {
                                post.videos.map((item) => {
                                    return(
                                        <MainVideoItems 
                                            title={item.video_title} 
                                            img={item.video_cover ? Services.apiServerUrl("languageShort")+item.video_cover : imageLists.generalNotFound} 
                                            url={() => [setIsOpenVideoModal(true), setVideoUrl(item.video_url)]} 
                                        />
                                    );
                                })
                            }

                        
                        </div>
                    </div>
                }



                { post.galleries == "" ? "" : 
                    <div className="display-flex flex-column align-items-center">
                        <ProductDetailAreaTitle title={TranslateData.Word("languageShort")[0]["product_galleries"]} />
                        <div className="productGalleries">
                            <SlideshowLightbox className="productGalleries">
                                {
                                    post.galleries.map((item) => {
                                        return(
                                            <img 
                                            src={item ? Services.apiServerUrl("languageShort")+item : imageLists.generalNotFound} 
                                            alt="" 
                                            className="productGalleriesItems" />
                                        );
                                    })
                                }
                            </SlideshowLightbox> 
                        </div>
                    </div>
                }



                { post.brochures == "" ? "" :  
                    <div className="display-flex flex-column align-items-center">
                        <ProductDetailAreaTitle title={TranslateData.Word("languageShort")[0]["product_brochures"]} />
                        <div className="display-flex flex-wrap justify-content-center">
                            {
                                post.brochures.map((item) => {
                                    return(
                                        <CataloguesBrochuresItems 
                                            title={item.brochure_title} 
                                            img={item.brochure_cover ? Services.apiServerUrl("languageShort")+item.brochure_cover : imageLists.generalNotFound} 
                                            url={"/DocumentPreview/?url="+item.brochure_url}
                                        />
                                    );
                                })
                            }
                        </div>
                    </div>
                }


                <BottomBarMenu />


            </div>
        </>

)
        


    );
}


function ProductDetailAreaTitle({title}){
    return(
        <strong className="font-size-26 font-bold display-flex mt-40 mb-20 text-black">{title}</strong>
    );
}






export default ProductDetail;