function Es(){
    return(
        [{
        
            // Menu
            "home":"HOGAR",
            "products":"PRODUCTOS",
            "categories":"CATEGORÍAS",
            "about":"AKFİX",
            "videos":"VÍDEOS",
            "catalogues":"CATÁLOGOS",
            "brochures":"FOLLETOS",
            "certificates":"CERTIFICADOS",
            "galleries":"GALERÍAS",
            "blog":"BLOG",
            "contact":"CONTACTO",


            // Home Translate
            "home_product" : "Producto",
            "home_categories" : "Categorías",
            "home_solutions" : "Soluciones",
            "home_videos" : "Vídeos",
            "home_new_products" : "Nuevos Productos",
            "home_blog" : "Blog",

             // General Translate
             "languages": "Idiomas",
             "search": "Buscar",
             "search_result": "Resultado de Búsqueda",
             "back": "Atrás",
             "file_uploading": "File Uploading...",

            // Product Detail Translate
            "properties" : "Propiedades",
            "application_areas" : "Áreas de Aplicación",
            "packaging" : "Embalaje",
            "product_videos" : "Vídeos",
            "product_galleries" : "Galerías",
            "product_brochures" : "Folletos",
            "stock_code" : "Número de inventario",
            "product_code" : "Código de producto",
            "type" : "Tipo",
            "volume" : "Volumen",
            "boxqty" : "Cantidad",




        }]
        
    )
}


export default Es;