import React from "react";


function MainBlogPostList({title, img, url}){
    return(
        <>
            <a href={url}>
                <div className="display-flex justify-content-left align-items-center">
                    <div className="blogImg">
                        <img src={img} alt="" />
                    </div>
                    <strong className="font-size-14 font-bold text-gray line-height-20 pr-20">{title}</strong>
                </div>
            </a>

            <div className="mainBlogLine"></div>
        </>
    );
}


export default MainBlogPostList;