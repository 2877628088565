function It(){
    return(
        [{

            // Menu
            "home":"HOME",
            "products":"PRODOTTI",
            "categories":"CATEGORIE",
            "about":"AKFİX",
            "videos":"VIDEOS",
            "catalogues":"CATALOGHI",
            "brochures":"DEPLIANT",
            "certificates":"CERTIFICATI",
            "galleries":"GALLERIA",
            "blog":"BLOG",
            "contact":"CONTATTI",

            // Home Translate
            "home_product" : "Prodotti",
            "home_categories" : "Categorie",
            "home_solutions" : "Soluzioni",
            "home_videos" : "Videos",
            "home_new_products" : "Nuovi Prodotti",
            "home_blog" : "Blog",

            // General Translate
            "languages": "Lingue",
            "search": "Ricerca",
            "search_result": "Risultato della ricerca",
            "back": "Indietro",
            "file_uploading": "File Uploading...",
            
            // Product Detail Translate
            "properties" : "Proprietà",
            "application_areas" : "Applicazione",
            "packaging" : "Confezione",
            "product_videos" : "Videos",
            "product_galleries" : "Gallerie",
            "product_brochures" : "Brochure",
            "stock_code" : "Codice magazzino",
            "product_code" : "Codice prodotto",
            "type" : "Tipo",
            "volume" : "Volume",
            "boxqty" : "Pz./Confezione",

        }]
        
    )
}


export default It;