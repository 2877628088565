import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

const BackNavigation = ({title, bgColor, color}) => {

    const navigate = useNavigate();

    return(
        <>
            <div style={{width:'100%', position:'fixed', backgroundColor:bgColor ? 'rgba('+bgColor+')' : '#fff', top:'0', zIndex:'99999', padding:'15px 10px 0px 10px'}}>
                <div className="width-100 display-flex justify-content-between align-items-center mb-15">
                    <div className="display-flex align-items-center">
                        <div style={{width:'40px', height:'40px', marginRight:'10px'}}>
                            <Link onClick={() => navigate(-1)} className="backMenu mr-10">
                                <FaArrowLeft fontSize={20} className="text-black" />
                            </Link>
                        </div>

                        <strong className="width-80 font-size-18 font-bold line-height-24 ml-15 text-black" style={{color: color ? 'rgb('+color+')' : ''}}>{title}</strong>
                    </div>
                </div>
                
            </div>
            <div style={{width:'100%', height:'70px'}}></div>
        </>
    );
}

export default BackNavigation;