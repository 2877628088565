import React from "react";
import {FaYoutube} from "react-icons/fa";
import { Link } from "react-router-dom";

function MainVideoItems({title, img, url}){
    return(
        <Link onClick={url} className="videoItems">
            <FaYoutube color="#fff" fontSize={34} className="mainVideoPlay" />
            <div className="videoItemBg"></div>
            <strong>{title}</strong>
            <img src={img} alt="" />
        </Link>
    );
}

export default MainVideoItems;
