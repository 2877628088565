function Fr(){
    return(
        [
            {

                // Menu
                "home":"MAISON",
                "products":"PRODUITS",
                "categories":"CATÉGORIES",
                "about":"AKFİX",
                "videos":"VIDÉOS",
                "catalogues":"CATALOGUE",
                "brochures":"BROCHURES",
                "certificates":"CERTIFICATS",
                "galleries":"GALERIE",
                "blog":"BLOG",
                "contact":"CONTACT",

                // Home Translate
                "home_product" : "Produit",
                "home_categories" : "Catégories",
                "home_solutions" : "Solutions",
                "home_videos" : "Vidéos",
                "home_new_products" : "Nouveaux Produits",
                "home_blog" : "Blog",

                 // General Translate
                "languages": "Langues",
                "search": "Recherche",
                "search_result": "Résultat de la recherche",
                "back": "Dos",
                "file_uploading": "File Uploading...",

                // Product Detail Translate
                "properties" : "Propriétés",
                "application_areas" : "Zone d'application",
                "packaging" : "Emballage",
                "product_videos" : "Vidéos",
                "product_galleries" : "Galeries",
                "product_brochures" : "Brochures",
                "stock_code" : "Code de Stock",
                "product_code" : "Code Produit",
                "type" : "Taper",
                "volume" : "Volume",
                "boxqty" : "Quantité",

            }
        ]
        
    )
}


export default Fr;