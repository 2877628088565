import axios from "axios";

class Services{

    getList = async (url) => {
        return await axios.get(url);
    }

    sessionAdd(name, data){
        return sessionStorage.setItem(name, data);
    }
    
    sessionGet(name){
       return sessionStorage.getItem(name);
    }
    
    sessionDefault(){
        return this.sessionAdd("language", 2);
    }


    apiServerUrl(name){

        if(sessionStorage.getItem(name) === "tr"){ return "https://www.akfix.com.tr/"; }
        else if(sessionStorage.getItem(name) === "en" || sessionStorage.getItem(name) === "fr" || sessionStorage.getItem(name) === "is"){
            return "https://www.akfix.com/";
        }
        else if(sessionStorage.getItem(name) === "bg"){ return "https://www.akfix.bg/"; }
        else if(sessionStorage.getItem(name) === "ru"){ return "https://ru.akfix.com/"; }
        else if(sessionStorage.getItem(name) === "it"){ return "https://www.akfix.it/"; }
        else if(sessionStorage.getItem(name) === "pl"){ return "https://www.akfix.pl/"; }
        else if(sessionStorage.getItem(name) === "rs"){ return "https://www.akfix.rs/"; }
        //else if(sessionStorage.getItem(name) === "uz"){ return "https://www.akfix.uz/"; }
        //else if(sessionStorage.getItem(name) === "fi"){ return "https://fi.akfix.com/"; }
        else if(sessionStorage.getItem(name) === "de"){ return "https://www.akfix.de.com/"; }
        else if(sessionStorage.getItem(name) === "usa"){ return "https://www.akfix-usa.com/"; }
        else{ return "https://www.akfix.com/"; }


        /*
        if(Number(sessionStorage.getItem(name)) === 1){
            return "https://www.akfix.com.tr/";
        }
        else if(Number(sessionStorage.getItem(name)) === 2){
            return "https://www.akfix.com/";
        }
        else if(Number(sessionStorage.getItem(name)) === 4){
            return "https://www.akfix.com/";
        }
        else if(Number(sessionStorage.getItem(name)) === 5){
            return "https://www.akfix.com/";
        }
        else{
            return "https://www.akfix.com/";
        }
        */
        
        
    }

}

export default Services = new Services();