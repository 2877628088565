import React, { useCallback } from "react";
import BackNavigation from "../../components/backNavigation/backNavigation";
import BottomBarMenu from "../../layout/general/BottomBarMenu";
import MainVideoItems from "../../layout/main/MainVideoItems";
import 'lightbox.js-react/dist/index.css';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/css/modal-video.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import closeIc from "../../assets/icons/close.svg";
import CataloguesBrochuresItems from "../../layout/media/CataloguesBrochuresItems";
import { useParams } from "react-router-dom";
import Services from "../../services/Services";
import Loading from "../../components/loading/Loading";
import parse from "html-react-parser";
import TranslateData from "../../services/TranslateData";
import imageLists from "../../contants/imageLists";



function ProductListDetail(){

    const [post, setPost] = React.useState([]);
    const [postDetail, setPostDetail] = React.useState([]);

    const [productListVisible, setProductListVisible] = React.useState(true);
    const [productDetailVisible, setProductDetailVisible] = React.useState(true);

    const params = useParams();

    const [isOpenProductModal, setIsOpenProductModal] = React.useState(false);
    const [productUrl, setProductUrl] = React.useState('');

    const [isOpenVideoModal, setIsOpenVideoModal] = React.useState(false);
    const [videoUrl, setVideoUrl] = React.useState('');

    
    const productList = useCallback(() => {
        try {
            Services.getList(Services.apiServerUrl("languageShort")+'api/data/products/?url='+params.url).then((response) =>  {
                setPost(response.data);
                setProductListVisible(false);
                });
            } catch (error) {
                console.log(error.message);
            }
    }, [params.url])


    const productDetail = useCallback(() => {
        try {
            Services.getList(Services.apiServerUrl("languageShort")+'api/data/categories/one_categories/?url='+params.url+"&languageID="+Services.sessionGet("language")).then((response) =>  {
                setPostDetail(response.data);
                setProductDetailVisible(false);
            });
            
        } catch (error) {
            console.log(error.message);
        }
    }, [params.url])

    
    React.useEffect(() => {
        productDetail();
        productList();
    }, [params.url, productDetail, productList]);


    if(productDetailVisible && productListVisible) return <Loading />
    return(

        <div>

            <ModalVideo
                channel="youtube"
                youtube={{ mute: 1, autoplay: 1 }}
                isOpen={isOpenVideoModal}
                videoId={videoUrl}
                onClose={() => setIsOpenVideoModal(false)}
            />

            <BackNavigation 
            title={postDetail.map((item) => parse(item.title))} 
            color={postDetail.map((item) => item.color ? item.color : '192, 0, 32')} 
            bgColor={postDetail.map((item) => item.color ? item.color : '192, 0, 32')+ ', 0.1'} />


            <Modal open={isOpenProductModal} onClose={setIsOpenProductModal} showCloseIcon={false}>
                <div className="modalClose" onClick={() => setIsOpenProductModal(false)}>
                    <img src={closeIc} alt="" />
                </div>

                 <img src={productUrl} alt=""  />
            </Modal>


            <div className="p-10 pt-20" style={{
                width:'100%', 
                overflow:'auto',  
                position:'absolute', 
                backgroundColor: 'rgba('+postDetail.map((item) => item.color ? item.color : '192, 0, 32')+', 0.1)', 
                top:'70px', 
                bottom:0}}>
            
                {
                    productListVisible ? <Loading /> :
                    post.map((post, i) => {
                        return(
                            /*
                            <ProductItems 
                                title={post.title} 
                                img={post.cover_img ? Services.apiServerUrl("languageShort")+post.cover_img : imageLists.productNotFound} 
                                iconBgColor={postDetail.map((item) => item.color ? item.color : '192, 0, 32')} 
                                iconColor={postDetail.map((item) => item.color ? item.color : '192, 0, 32')} 
                                url={"/productDetail/"+post.url} />
                            */
                            <div style={{background:'#fff', marginBottom:20, borderRadius:10, boxShadow:'0px 0px 10px 0px #d4d4d4'}}>

                                <div style={{width:'100%', 
                                height:'auto', 
                                padding:6, 
                                paddingLeft:15, 
                                paddingRight:15,
                                lineHeight:'26px',
                                backgroundColor:'#C00020',
                                borderTopLeftRadius:10,
                                borderTopRightRadius:10}}>
                                    <ProductDetailAreaTitle title={post.title} textColor={'#fff'} />
                                </div>

                                <div style={{padding:10}}>


                                <Carousel autoPlay={true} showIndicators={true} showArrows={true} emulateTouch={true} showThumbs={false} showStatus={false} infiniteLoop={true}>
                                    {
                                        post.product_images == null ? <Loading /> : 
                                        post.product_images.map((item) => 
                                            <div 
                                            className="productDetailImg" 
                                            onClick={() => [setIsOpenProductModal(true), setProductUrl(item.img ? Services.apiServerUrl("languageShort")+item.img.replace("/small", "") : imageLists.productDetailNotFound)]} style={{background:'#fff'}}>
                                                <img src={item.img ? Services.apiServerUrl("languageShort")+item.img : imageLists.productDetailNotFound} alt="" />

                                            </div>
                                        )
                                    }
                                </Carousel>


                                    
                                <div className="productDescription mt-40">
                                    {parse(post.more_information)}
                                </div>

                                <div className="ProductProperties-ApplicationAreas" style={{margin:10, marginTop:30}}>
                                    <ProductDetailAreaTitle title={TranslateData.Word("languageShort")[0]["properties"]} textColor='#C4000B'/>
                                    {parse(post.properties)}
                                </div>

                                <div className="ProductProperties-ApplicationAreas" style={{margin:10, marginTop:30}}>
                                    <ProductDetailAreaTitle title={TranslateData.Word("languageShort")[0]["application_areas"]} textColor='#C4000B'/>
                                    {parse(post.application_areas)}
                                </div>


                                { post.packages === "" ? "" :  
                                    <div className="packagingContainer" style={{marginTop:30}}>
                                        <ProductDetailAreaTitle title={TranslateData.Word("languageShort")[0]["packaging"]} textColor='#C4000B'/>
                                        <table className="packagingTable">
                                            <tr>
                                                <th>{TranslateData.Word("languageShort")[0]["product_code"]}</th>
                                                <th>{TranslateData.Word("languageShort")[0]["type"]}</th>
                                                <th>{TranslateData.Word("languageShort")[0]["volume"]}</th>
                                                <th>{TranslateData.Word("languageShort")[0]["boxqty"]}</th>
                                            </tr>
                                            {parse(post.packages)}
                                        
                                        </table>
                                    </div>
                                }

                 
                                                
                                { post.videos == "" ? "" :  
                                    <div className="display-flex flex-column align-items-center">
                                        <ProductDetailAreaTitle title={TranslateData.Word("languageShort")[0]["product_videos"]} textColor='#C4000B'/>
                                        <div className="display-flex justify-content-center flex-wrap">


                                            {
                                                post.videos.map((item) => {
                                                    return(
                                                        <MainVideoItems 
                                                            title={item.video_title} 
                                                            img={item.video_cover ? Services.apiServerUrl("languageShort")+item.video_cover : imageLists.generalNotFound} 
                                                            url={() => [setIsOpenVideoModal(true), setVideoUrl(item.video_url)]} 
                                                        />
                                                    );
                                                })
                                            }

                                        
                                        </div>
                                    </div>
                                }



                                { post.brochures == "" ? "" :  
                                    <div className="display-flex flex-column align-items-center">
                                        <ProductDetailAreaTitle title={TranslateData.Word("languageShort")[0]["product_brochures"]} />
                                        <div className="display-flex flex-wrap justify-content-center">
                                            {
                                                post.brochures.map((item) => {
                                                    return(
                                                        <CataloguesBrochuresItems 
                                                            title={item.brochure_title} 
                                                            img={item.brochure_cover ? Services.apiServerUrl("languageShort")+item.brochure_cover : imageLists.generalNotFound} 
                                                            url={"/DocumentPreview/?url="+item.brochure_url}
                                                        />
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                }


                                </div>



                            </div>

                        );
                    })
                }

                <BottomBarMenu />

            </div>
        </div>
    );
}




function ProductDetailAreaTitle({title, textColor}){
    return(
        <strong className="font-size-18 font-bold display-flex mb-5 mt-5 text-black p-5" style={{color:textColor}}>{title}</strong>
    );
}



export default ProductListDetail;