import React from "react";
import BackNavigation from "../../components/backNavigation/backNavigation";
import MainTitles from "../../layout/main/MainTitles";
import ProductCategoriesItems from "../../layout/main/ProductCategoriesItems";
import SolutionCategoriesItems from "../../layout/main/SolutionCategoriesItems";
import BottomBarMenu from "../../layout/general/BottomBarMenu";
import Services from "../../services/Services";
import Loading from "../../components/loading/Loading";
import parse from "html-react-parser";
import TranslateData from "../../services/TranslateData";
import imageLists from "../../contants/imageLists";


function Categories(){

    const [categoriesList, setCategoriesList] = React.useState([]);
    const [solutionCategoriesList, setSolutionCategoriesList] = React.useState([]);
    const [visible, setVisible] = React.useState(true);


    // Product Categories
    const productCategoriesList = async () => {
        try {
            await Services.getList(Services.apiServerUrl("languageShort")+'api/data/categories/product_categories/?languageID='+sessionStorage.getItem("language")).then((response) =>  {
                setCategoriesList(response.data);
            });
            setVisible(false);
        } catch (error) {
            console.log(error.message);
        }
    }


    // Solution Categories
    const productSolutionList = async () => {
        try {
            await Services.getList(Services.apiServerUrl("languageShort")+'api/data/categories/solution_categories/?languageID='+sessionStorage.getItem("language")).then((response) =>  {
                setSolutionCategoriesList(response.data);
            });
            setVisible(false);
        } catch (error) {
            console.log(error.message);
        }
    }


    React.useEffect(() => {
        productCategoriesList();
        productSolutionList();
    }, []);

    
    if(visible) return <Loading />

    return(
        <>
            <BackNavigation title={TranslateData.Word("languageShort")[0]["categories"]} />

            <div className="p-10 pt-20">
            
                    <div className="container-100 display-flex flex-wrap justify-content-between">

                        {
                            categoriesList.map((item, categoriesKey) => {
                                return(
                                    <ProductCategoriesItems 
                                    key={categoriesKey} 
                                    title={parse(item.title)} 
                                    img={item.mobil_img_url ? Services.apiServerUrl("languageShort")+item.mobil_img_url : imageLists.categoriesNotFound} 
                                    bgColor={item.color} url={"/products/"+item.url} />
                                );
                            })
                        }
                       
                    </div>


                    <MainTitles title={TranslateData.Word("languageShort")[0]["home_solutions"]} />
                    <div className="solutionCategoriesContainer">
                        {
                            solutionCategoriesList.map((item, solutionCategoriesKey) => {
                                return(
                                    <SolutionCategoriesItems 
                                    key={solutionCategoriesKey} 
                                    title={item.title} 
                                    img={item.img_url ? Services.apiServerUrl("languageShort")+item.img_url : imageLists.solutionNotFound} 
                                    url={"/products/"+item.url}  />
                                );
                            })
                        }
                    </div>

                <BottomBarMenu />

            </div>
        </>
    );
}


export default Categories;