function Ru(){
    return(
        [{

            // Menu
            "home":"ДОМ",
            "products":"ПРОДУКТЫ",
            "categories":"КАТЕГОРИИ",
            "about":"AKFİX",
            "videos":"ВИДЕО",
            "catalogues":"КАТАЛОГИ",
            "brochures":"БРОШЮРЫ",
            "certificates":"СЕРТИФИКАТЫ",
            "galleries":"ГАЛЕРЕИ",
            "blog":"БЛОГ",
            "contact":"КОНТАКТ",

            // Home Translate
            "home_product" : "Продукт",
            "home_categories" : "Категории",
            "home_solutions" : "Решения",
            "home_videos" : "Видео",
            "home_new_products" : "новые продукты",
            "home_blog" : "Блог",

            // General Translate
            "languages": "Языки",
            "search": "Поиск",
            "search_result": "Результат поиска",
            "back": "Назад",
            "file_uploading": "File Uploading...",

            // Product Detail Translate
            "properties" : "свойства",
            "application_areas" : "Область применения",
            "packaging" : "пакет",
            "product_videos" : "Видео",
            "product_galleries" : "Галереи",
            "product_brochures" : "Брошюры",
            "stock_code" : "Код акций",
            "product_code" : "Код продукта",
            "type" : "Тип",
            "volume" : "Объем",
            "boxqty" : "Кол-во",

        }]
        
    )
}


export default Ru;