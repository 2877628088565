import React from "react";
import Drawer from "react-modern-drawer";
import 'react-modern-drawer/dist/index.css'
import { FaArrowRight } from "react-icons/fa";
import logoWhite from "../../assets/images/logos/akfix-white.svg";
import homeIc from "../../assets/icons/drawer-menu/drawer-home-ic.svg";
import productIc from "../../assets/icons/drawer-menu/drawer-products-ic.svg";
import aboutIc from "../../assets/icons/drawer-menu/drawer-about-ic.svg";
import videoIc from "../../assets/icons/drawer-menu/drawer-videos-ic.svg";
import catalogueIc from "../../assets/icons/drawer-menu/drawer-catalogues-ic.svg";
import brochureIc from "../../assets/icons/drawer-menu/drawer-brochures-ic.svg";
import certificateIc from "../../assets/icons/drawer-menu/drawer-certificates-ic.svg";
import galleriesIc from "../../assets/icons/drawer-menu/drawer-galleries-ic.svg";
import blogIc from "../../assets/icons/drawer-menu/drawer-blogs-ic.svg";
import contactIc from "../../assets/icons/drawer-menu/drawer-contact-ic.svg";
import { Link } from "react-router-dom";
import TranslateData from "../../services/TranslateData";
import Services from "../../services/Services";

function DrawerMenu({open, onClose}){

    return(
        <>
            <Drawer
                open={open}
                onClose={onClose}
                direction='left'
                className='drawerMenuContainer'
                zIndex={'999999'}>
                <div className="drawerMenuContent">
                    <a href={"/"}>
                        <img src={logoWhite} className="drawerLogo" alt="" />
                    </a>
                    <div className="drawerMenuLine"></div>

                    <div className="display-flex flex-column">
                        <DrawerMenuItems title={TranslateData.Word("languageShort")[0]["home"]} icon={homeIc} url={"/"} />
                        <DrawerMenuItems title={TranslateData.Word("languageShort")[0]["products"]} icon={productIc} url={"/Categories"} />
                        <DrawerMenuItems title={TranslateData.Word("languageShort")[0]["about"]} icon={aboutIc} url={"/About"} />
                        <DrawerMenuItems title={TranslateData.Word("languageShort")[0]["videos"]} icon={videoIc} url={"/Videos"} />
                        <DrawerMenuItems title={TranslateData.Word("languageShort")[0]["catalogues"]} icon={catalogueIc} url={"/Catalogues"} />
                        <DrawerMenuItems title={TranslateData.Word("languageShort")[0]["brochures"]} icon={brochureIc} url={"/Brochures"} />
                        <DrawerMenuItems title={TranslateData.Word("languageShort")[0]["certificates"]} icon={certificateIc} url={"/Certificates"} />
                        <DrawerMenuItems title={TranslateData.Word("languageShort")[0]["galleries"]} icon={galleriesIc} url={"/Galleries"} />
                        {
                            (Services.sessionGet("languageShort") == null || 
                            Services.sessionGet("languageShort") == "tr" || 
                            Services.sessionGet("languageShort") == "en")  ? 
                            <DrawerMenuItems title={TranslateData.Word("languageShort")[0]["blog"]} icon={blogIc} url={"/Blogs"} />
                            : ""
                        }
                        <DrawerMenuItems title={TranslateData.Word("languageShort")[0]["contact"]} icon={contactIc} url={"/Contact"} />
                    </div>
           
                </div>
            </Drawer>
        </>
    );
}



function DrawerMenuItems({title, icon, url}){
    return(
        <>
            <Link to={url}>
                <div className="drawerMenuItems display-flex justify-content-between align-items-center">
                    <div className="display-flex justify-content-left align-items-center">
                        <img src={icon} alt="" />
                        <strong className="text-white font-size-16 font-bold">{title}</strong>
                    </div>
                    
                    <FaArrowRight fontSize={12} color="#E60127" />

                </div>
            </Link>

            <div className="drawerMenuListLine"></div>
        </>
    );
}


export default DrawerMenu;