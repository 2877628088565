import React from "react";
import BackNavigation from "../../components/backNavigation/backNavigation";
import BottomBarMenu from "../../layout/general/BottomBarMenu";
import MainVideoItems from "../../layout/main/MainVideoItems";
import ModalVideo from 'react-modal-video';
import 'react-modal-video/css/modal-video.css';
import Services from "../../services/Services";
import Loading from "../../components/loading/Loading";
import TranslateData from "../../services/TranslateData";
import imageLists from "../../contants/imageLists";

function Videos(){

    const [videosPost, setVideosPost] = React.useState([]);
    const [visible, setVisible] = React.useState(true);

    const [isOpenVideoModal, setIsOpenVideoModal] = React.useState(false);
    const [videoUrl, setVideoUrl] = React.useState('');


    const videosList = async () => {
        try {
             await Services.getList(Services.apiServerUrl("languageShort")+'api/data/videos/?languageID='+sessionStorage.getItem("language")).then((response) =>  {
                setVideosPost(response.data);
             });
             setVisible(false);
        } catch (error) {
         console.log(error.message);
        }
     }

     React.useEffect(() => {
        videosList();
     }, []);



    if(visible) return <Loading />

    return(
        <>
            <BackNavigation title={TranslateData.Word("languageShort")[0]["videos"]} />

            <div className="p-10 pt-20 line-height-24">
                
                <div className="display-flex flex-column align-items-center">
                    <div className="display-flex flex-wrap justify-content-center">

                        <ModalVideo
                            channel="youtube"
                            youtube={{ mute: 1, autoplay: 1 }}
                            isOpen={isOpenVideoModal}
                            videoId={videoUrl}
                            onClose={() => setIsOpenVideoModal(false)}
                            
                        />

                        {
                            videosPost.map((item, videoKey) => {
                                return(
                                    <MainVideoItems 
                                        key={videoKey} 
                                        title={item.title} 
                                        img={item.img_url ? Services.apiServerUrl("languageShort")+item.img_url : imageLists.generalNotFound} 
                                        url={() => [setIsOpenVideoModal(true), setVideoUrl(item.video_url)]} 
                                    />
                                );
                            })
                        }

                    </div>
                </div>

            <BottomBarMenu />

            </div>
        </>
    );
}





export default Videos;