function Tr(){
    return(
        [{
            
            // Menu
            "home":"ANASAYFA",
            "products":"ÜRÜNLER",
            "categories":"KATEGORİLER",
            "about":"AKFİX",
            "videos":"VİDEOLAR",
            "catalogues":"KATALOGLAR",
            "brochures":"BROŞÜRLER",
            "certificates":"SERTİFİKALAR",
            "galleries":"GALERİ",
            "blog":"BLOG",
            "contact":"İLETİŞİM",

            // Home Translate
            "home_product" : "Ürün",
            "home_categories" : "Kategorileri",
            "home_solutions" : "Çözümler",
            "home_videos" : "Videolar",
            "home_new_products" : "Yeni Ürünler",
            "home_blog" : "Blog",

             // General Translate
             "languages": "Diller",
             "search": "Ara",
             "search_result": "Arama Sonuçları",
             "back": "Geri",
             "file_uploading": "Dosya Yükleniyor...",

            // Product Detail Translate
            "properties" : "Özellikler",
            "application_areas" : "Uygulama Alanları",
            "packaging" : "Ambalaj",
            "product_videos" : "Videolar",
            "product_galleries" : "Galeri",
            "product_brochures" : "Broşürler",
            "stock_code" : "Stok Kodu",
            "product_code" : "Ürün Kodu",
            "type" : "Tür/Renk",
            "volume" : "Hacim",
            "boxqty" : "Koli İçi Adet",

        }]
        
    )
}


export default Tr;