import React from "react";
import ProductCategoriesItems from "../../layout/main/ProductCategoriesItems";
import SolutionCategoriesItems from "../../layout/main/SolutionCategoriesItems";
import MainVideoItems from "../../layout/main/MainVideoItems";
import MainProductList from "../../layout/main/MainProductList";
import MainBlogPostList from "../../layout/main/MainBlogPostList";
import AppBar from "../../layout/general/AppBar";
import MainTitles from "../../layout/main/MainTitles";
import BottomBarMenu from "../../layout/general/BottomBarMenu";
import ModalVideo from 'react-modal-video';
import 'react-modal-video/css/modal-video.css';
import Services from "../../services/Services";
import Loading from "../../components/loading/Loading";
import parse from "html-react-parser";
import TranslateData from "../../services/TranslateData";
import imageLists from "../../contants/imageLists";


function Home(){

    const [isOpenVideoModal, setIsOpenVideoModal] = React.useState(false);
    const [videoUrl, setVideoUrl] = React.useState('');


    const [productCategoriesPost, setProductCategoriesPost] = React.useState([]);
    const [solutionCategoriesPost, setSolutionCategoriesPost] = React.useState([]);
    const [mainVideoListPost, setMainVideoListPost] = React.useState([]);
    const [mainNewProductListPost, setMainNewProductListPost] = React.useState([]);
    const [mainBlogListPost, setMainBlogListPost] = React.useState([]);


    //  const [visible, setVisible] = React.useState(true);
    const [productCategoriesVisible, setPoductCategoriesVisible] = React.useState(true);
    const [solutionCategoriesVisible, setSolutionCategoriesVisible] = React.useState(true);
    const [mainVideoVisible, setMainVideoVisible] = React.useState(true);
    const [mainNewProductVisible, setMainNewProductVisible] = React.useState(true);
    const [mainBlogVisible, setMainBlogVisible] = React.useState(true);
    


    const productCategoriesList = async () => {
       try {
            await Services.getList(Services.apiServerUrl("languageShort")+'api/data/categories/product_categories/?languageID='+sessionStorage.getItem("language")).then((response) =>  {
                setProductCategoriesPost(response.data);
                setPoductCategoriesVisible(false);
            });
        } catch (error) {
        console.log(error.message);
       }
    }

    const solutionCategoriesList = async () => {
        try {
            await Services.getList(Services.apiServerUrl("languageShort")+'api/data/categories/solution_categories/?languageID='+sessionStorage.getItem("language")).then((response) =>  {
                setSolutionCategoriesPost(response.data);
                setSolutionCategoriesVisible(false);
                });
        } catch (error) {
            console.log(error.message);
        }
    }

    const mainVideoList = async () => {
        try {
            await Services.getList(Services.apiServerUrl("languageShort")+'api/data/videos/main_videos.php?languageID='+sessionStorage.getItem("language")).then((response) => {
                setMainVideoListPost(response.data);
                setMainVideoVisible(false);
            });
        } catch (error) {
            console.log(error.message);
        }
    }

    const mainNewProductList = async () => {
        try {
            await Services.getList(Services.apiServerUrl("languageShort")+'api/data/new_products/?languageID='+sessionStorage.getItem("language")).then((response) => {
                setMainNewProductListPost(response.data);
                setMainNewProductVisible(false);
            });
        } catch (error) {
            console.log(error.message);
        }
    }

    const mainBlogList = async () => {
        try {
            await Services.getList(Services.apiServerUrl("languageShort")+'api/data/blogs/?limit=5&languageID='+sessionStorage.getItem("language")).then((response) => {
                setMainBlogListPost(response.data);
                setMainBlogVisible(false);
            });
        } catch (error) {
            console.log(error.message);
        }
    }


    React.useEffect(() => {
        
        // Uygulama açılışında varsayılan dili otomatik aktif et
        if (Services.sessionGet("language") === null) {
            Services.sessionDefault();
        } 

        productCategoriesList();
        solutionCategoriesList();
        mainVideoList();
        mainNewProductList();
        mainBlogList();
    }, []);


    if(productCategoriesVisible && solutionCategoriesVisible && mainVideoVisible && mainNewProductVisible && mainBlogVisible) return <Loading />
    

    return(

        <>
            <AppBar />

            <div className="width-100 p-10 bg-white">

                <div style={{width:'100%', height:'60px'}}></div>

           
                <MainTitles subTitle={TranslateData.Word("languageShort")[0]["home_product"]} title={TranslateData.Word("languageShort")[0]["home_categories"]} />
                

                <div className="container-100 display-flex flex-wrap justify-content-between">
                    {
                        productCategoriesVisible ? <Loading /> :
                        productCategoriesPost.map((item) => {
                            return(
                                <ProductCategoriesItems 
                                    title={parse(item.title)} 
                                    img={item.mobil_img_url ? Services.apiServerUrl("languageShort")+item.mobil_img_url : imageLists.categoriesNotFound} 
                                    bgColor={item.color} 
                                    url={"/products/"+item.url} />
                            );
                        })
                    }
                </div>


                <MainTitles title={TranslateData.Word("languageShort")[0]["home_solutions"]} />
                <div className="solutionCategoriesContainer">
                    {
                        solutionCategoriesVisible ? <Loading /> :
                        solutionCategoriesPost.map((item) => {
                            return(
                                <SolutionCategoriesItems 
                                title={item.title} 
                                img={item.img_url ? Services.apiServerUrl("languageShort")+item.img_url : imageLists.solutionNotFound} 
                                url={"/Products/"+item.url}  />
                            );
                        })
                    }
                </div>


                { mainVideoListPost.length > 0 ? <MainTitles title={TranslateData.Word("languageShort")[0]["home_videos"]} /> : "" }
                <div className="videoContainer">

                <ModalVideo
                    channel="youtube"
                    youtube={{ mute: 1, autoplay: 1 }}
                    isOpen={isOpenVideoModal}
                    videoId={videoUrl}
                    onClose={() => setIsOpenVideoModal(false)}
                />

                {
                    mainVideoVisible ? <Loading /> :
                    mainVideoListPost.map((item) => {
                        return(
                            <MainVideoItems 
                            title={item.title} 
                            img={item.img_url ? Services.apiServerUrl("languageShort")+item.img_url : imageLists.generalNotFound} 
                            url={() => [setIsOpenVideoModal(true), setVideoUrl(item.video_url)]} />
                        );
                    })
                }
                
                </div>


                { mainNewProductListPost.length > 0 ? <MainTitles title={TranslateData.Word("languageShort")[0]["home_new_products"]} /> : "" }
                <div className="mainProductListContainer">
                    {
                        mainNewProductVisible ? <Loading /> :
                        mainNewProductListPost.map((item) => {
                            return(
                                <MainProductList 
                                title={item.title} 
                                img={item.cover_img ? Services.apiServerUrl("languageShort")+item.cover_img : imageLists.generalNotFound} 
                                url={"/productDetail/"+item.url} label={"New"} />
                            );
                        })
                    }
                </div>



                { mainBlogListPost.length > 0 ? <MainTitles title={TranslateData.Word("languageShort")[0]["home_blog"]} /> : "" }
                <div className="display-flex flex-column">
                    {
                        mainBlogVisible ? <Loading /> :
                        mainBlogListPost.map((item) => {
                            return(
                                <MainBlogPostList 
                                title={item.title} 
                                img={item.img_url ? Services.apiServerUrl("languageShort")+item.img_url : imageLists.generalNotFound} 
                                url={"/blogDetail/"+item.url}/>
                                );
                        })
                    }
                </div>


                <BottomBarMenu />
                
            </div>

        </>
    );
}



export default Home;