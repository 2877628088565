import React from "react";
import ReactLoading from "react-loading";


const Loading = () => {
    return(
        <div style={{width:"100%", 
        height:"100%", 
        position: "fixed", 
        zIndex:999999,
        top:0, 
        right:0, 
        left:0, 
        bottom:0, 
        backgroundColor:'#fff', 
        display:'flex', 
        justifyContent:'center', 
        alignItems:'center' }}>
            
            {
               //<strong style={{fontSize:20, color:'#fff'}}>LOADING...</strong>
            }

            <ReactLoading type="bubbles" color={"#C00020"} />

            </div>
    );
}

export default Loading