import React from "react";
import BackNavigation from "../../components/backNavigation/backNavigation";
import BottomBarMenu from "../../layout/general/BottomBarMenu";
import { useParams } from "react-router-dom";
import Services from "../../services/Services";
import Loading from "../../components/loading/Loading";
import parse from "html-react-parser";

function BlogDetail(){

    const params = useParams();

    const [blogDetailPost, setBlogDetailPost] = React.useState([]);
    const [visible, setVisible] = React.useState(true);

    React.useEffect(() => {
        Services.getList(Services.apiServerUrl("languageShort")+'api/data/blogs/detail/?url='+[params.url]).then((response) =>  {
        setBlogDetailPost(response.data);
        setVisible(false);
        }).catch((error) => {
            console.log(error.message)
        })
    }, [params.url]);


    return(

        visible ? <Loading /> :
        blogDetailPost.map((item) => 
        <>
            <BackNavigation title={item.title} />

            <div className="blogContent p-10 pt-30">

                {parse(item.more_information)}

            </div>

            <BottomBarMenu />

        </>
        )
    );
}


export default BlogDetail;