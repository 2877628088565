import React, { Component } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../pages/home/Home";
import About from "../pages/about/About";
import Categories from "../pages/categories/Categories";
import Products from "../pages/products/Products";
import ProductDetail from "../pages/productDetail/ProductDetail";
import Catalogues from "../pages/catalogues/Catalogues";
import Brochures from "../pages/brochures/Brochures";
import Galleries from "../pages/galleries/Galleries";
import Videos from "../pages/videos/Videos";
import Certificates from "../pages/certificates/Certificates";
import Contact from "../pages/contact/Contact";
import Blogs from "../pages/blogs/Blogs";
import BlogDetail from "../pages/blogDetail/blogDetail";
import Calculator from "../pages/calculator/Calculator";
import Search from "../pages/search/Search";
import DocumentPreview from "../pages/documentPreview/DocumentPreview";
import ProductListDetail from "../pages/productListDetail/ProductListDetail";


class Routers extends Component{
    render(){
        return(
            <Router>
                <Routes>
                    <Route path="/" element={<Home />}></Route>
                    <Route path="About" element={<About />}></Route>
                    <Route path="Categories" element={<Categories />}></Route>
                    <Route path="products/:url" element={<Products />}></Route>
                    <Route path="Products" element={<Products />}></Route>
                    <Route path="productDetail/:url" element={<ProductDetail />}></Route>
                    <Route path="productListDetail/:url" element={<ProductListDetail />}></Route>
                    <Route path="Catalogues" element={<Catalogues />}></Route>
                    <Route path="Brochures" element={<Brochures />}></Route>
                    <Route path="Galleries" element={<Galleries />}></Route>
                    <Route path="Videos" element={<Videos />}></Route>
                    <Route path="Certificates" element={<Certificates />}></Route>
                    <Route path="Blogs" element={<Blogs />}></Route>
                    <Route path="blogDetail/:url" element={<BlogDetail />}></Route>
                    <Route path="Calculator" element={<Calculator />}></Route>
                    <Route path="Contact" element={<Contact />}></Route>
                    <Route path="Search" element={<Search />}></Route>
                    <Route path=":DocumentPreview" element={<DocumentPreview />}></Route>
                    <Route path="*" element={<Home />}></Route>
                </Routes>
            </Router>
        );
    }
}


export default Routers;