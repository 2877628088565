function Pl(){
    return(
        [{

            // Menu
            "home":"HOME",
            "products":"PRODUKTY",
            "categories":"KATEGORIE",
            "about":"AKFİX",
            "videos":"VIDEOS",
            "catalogues":"KATALOG",
            "brochures":"BROSZURY",
            "certificates":"CERTIFICATES",
            "galleries":"GALERIA",
            "blog":"BLOG",
            "contact":"KONTAKT",

            // Home Translate
            "home_product" : "Produkty",
            "home_categories" : "Kategorie",
            "home_solutions" : "Rozwiazania",
            "home_videos" : "Filmy",
            "home_new_products" : "Nowe Produkty",
            "home_blog" : "Blog",

            // General Translate
            "languages": "Języki",
            "search": "Szukaj",
            "search_result": "Wynik wyszukiwania",
            "back": "Z powrotem",
            "file_uploading": "File Uploading...",
            
            // Product Detail Translate
            "properties" : "Nieruchomości",
            "application_areas" : "Obszary zastosowań",
            "packaging" : "Opakowania",
            "product_videos" : "Filmy",
            "product_galleries" : "Galleries",
            "product_brochures" : "Broszury",
            "stock_code" : "Kod giełdowy",
            "product_code" : "Kod produktu",
            "type" : "Typ",
            "volume" : "Objętość",
            "boxqty" : "Ilość w Pudełku",

        }]
        
    )
}


export default Pl;