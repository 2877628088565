function Fi(){
    return(
        [{

            // Menu
            "home":"KOTI",
            "products":"TUOTTEET",
            "categories":"KATEGORIAT",
            "about":"AKFİX",
            "videos":"VIDEOT",
            "catalogues":"LUETTELO",
            "brochures":"ESITTEET",
            "certificates":"SERTIFIKAATIT",
            "galleries":"GALLERIA",
            "blog":"BLOG",
            "contact":"OTA YHTEYTTÄ",

            // Home Translate
            "home_product" : "Tuotteet",
            "home_categories" : "Kategoriat",
            "home_solutions" : "Ratkaisut",
            "home_videos" : "Videot",
            "home_new_products" : "Uudet Tuotteet",
            "home_blog" : "Blog",

            // General Translate
            "languages": "Kielet",
            "search": "Tuotehaku",
            "search_result": "Hakutulokset",
            "back": "Takaisin",
            "file_uploading": "File Uploading...",
            

            // Product Detail Translate
            "properties" : "Ominaisuudet",
            "application_areas" : "Käyttöalueet",
            "packaging" : "Pakkaus",
            "product_videos" : "Tuotevideot",
            "product_galleries" : "Galleria",
            "product_brochures" : "Esite",
            "stock_code" : "Varastokoodi",
            "product_code" : "Tuotekoodi",
            "type" : "Tyyppi",
            "volume" : "Äänenvoimakkuus",
            "boxqty" : "Määrä",

            

        }]
        
    )
}


export default Fi;