import React from "react";
import BackNavigation from "../../components/backNavigation/backNavigation";
import BottomBarMenu from "../../layout/general/BottomBarMenu";
import { SlideshowLightbox } from "lightbox.js-react";
import Services from "../../services/Services";
import Loading from "../../components/loading/Loading";
import TranslateData from "../../services/TranslateData";
import imageLists from "../../contants/imageLists";

function Galleries(){

    const [galleriesPost, setGalleriesPost] = React.useState([]);
    const [visible, setVisible] = React.useState(true);

    const galleriesList = async () => {
        try {
             await Services.getList(Services.apiServerUrl("languageShort")+'api/data/galleries/?languageID='+sessionStorage.getItem("language")).then((response) =>  {
                setGalleriesPost(response.data);
             });
             setVisible(false);
        } catch (error) {
         console.log(error.message);
        }
     }

     React.useEffect(() => {
        galleriesList();
     }, []);


    if(visible) return <Loading />


    return(
        <>
            <BackNavigation title={TranslateData.Word("languageShort")[0]["galleries"]} />

            <div className="p-10 pt-20 line-height-24">
                
                <div className="display-flex flex-column align-items-center">
                    <div className="display-flex flex-wrap justify-content-center">

                    <SlideshowLightbox className="productGalleries">
                        {
                            galleriesPost.map((item, galleriesKey) => {
                                return(
                                    <img 
                                    key={galleriesKey} 
                                    src={item.img_url ? Services.apiServerUrl("languageShort")+item.img_url : imageLists.generalNotFound} 
                                    alt="" 
                                    className="productGalleriesItems" />
                                );
                            })
                        }
                    </SlideshowLightbox> 

                    </div>
                </div>

                <BottomBarMenu />

            </div>
        </>
    );
}





export default Galleries;