function Rs(){
    return(
        [{

            // Menu
            "home":"POČETNA STRANA",
            "products":"PROIZVODI",
            "categories":"КАТЕГОРИЈЕ",
            "about":"AKFİX",
            "videos":"VIDEO SNIMCI",
            "catalogues":"KATALOG",
            "brochures":"BROŠURE",
            "certificates":"SERTIFIKATI",
            "galleries":"GALERIJA",
            "blog":"BLOG",
            "contact":"KONTAKT",

            // Home Translate
            "home_product" : "Proizvodi",
            "home_categories" : "Категорије",
            "home_solutions" : "Rešenja",
            "home_videos" : "Video Snimci",
            "home_new_products" : "Novi Proizvodi",
            "home_blog" : "Blog",

            // General Translate
            "languages": "Jezici",
            "search": "Pretraga Proizvoda",
            "search_result": "Rezultati Pretrage",
            "back": "Назад",
            "file_uploading": "File Uploading...",
            
            // Product Detail Translate
            "properties" : "Karakteristike",
            "application_areas" : "Područja primene",
            "packaging" : "Pakovanja",
            "product_videos" : "Video snimci proizvoda",
            "product_galleries" : "Galerija",
            "product_brochures" : "Brošure",
            "stock_code" : "Šifra zaliha",
            "product_code" : "Šifra proizvoda",
            "type" : "Tip",
            "volume" : "Zapremina",
            "boxqty" : "Količina u kutiji",

        }]
        
    )
}


export default Rs;