import React from "react";
import { Link } from "react-router-dom";

function CataloguesBrochuresItems({title, img, url}){
    return(
        <Link to={url} className="productBrochureItems">
            <div className="productBrochureCover">
                <img src={img} alt="" />
            </div>
            <strong>{title}</strong>
        </Link>
    );
}

export default CataloguesBrochuresItems;

