import React, { useCallback } from "react";
import BackNavigation from "../../components/backNavigation/backNavigation";
import BottomBarMenu from "../../layout/general/BottomBarMenu";
import { FaArrowRight } from "react-icons/fa";
import { useParams, Link } from "react-router-dom";
import Services from "../../services/Services";
import Loading from "../../components/loading/Loading";
import parse from "html-react-parser";
import imageLists from "../../contants/imageLists";

function Products(){

    const [post, setPost] = React.useState([]);
    const [postDetail, setPostDetail] = React.useState([]);

    const [productListVisible, setProductListVisible] = React.useState(true);
    const [productDetailVisible, setProductDetailVisible] = React.useState(true);

    const params = useParams();

    
    const productList = useCallback(() => {
        try {
            Services.getList(Services.apiServerUrl("languageShort")+'api/data/products/?url='+params.url).then((response) =>  {
                setPost(response.data);
                setProductListVisible(false);
                });
            } catch (error) {
                console.log(error.message);
            }
    }, [params.url])



    const productDetail = useCallback(() => {
        try {
            Services.getList(Services.apiServerUrl("languageShort")+'api/data/categories/one_categories/?url='+params.url+"&languageID="+Services.sessionGet("language")).then((response) =>  {
                setPostDetail(response.data);
                setProductDetailVisible(false);
            });
            
        } catch (error) {
            console.log(error.message);
        }
    }, [params.url])

    
    
    React.useEffect(() => {
        productDetail();
        productList();
    }, [params.url, productDetail, productList]);



    if(productDetailVisible && productListVisible) return <Loading />
    return(
        <div>
            <BackNavigation 
            title={postDetail.map((item) => parse(item.title))} 
            color={postDetail.map((item) => item.color ? item.color : '192, 0, 32')} 
            bgColor={postDetail.map((item) => item.color ? item.color : '192, 0, 32')+ ', 0.1'} />

            <div className="p-10 pt-20" style={{width:'100%', overflow:'auto',  position:'absolute', backgroundColor: 'rgba('+postDetail.map((item) => item.color ? item.color : '192, 0, 32')+', 0.1)', top:'70px', bottom:0}}>
            
      {
        /*
            <a href={"../productListDetail/"+params.url} style={{position:'fixed', zIndex:9999, padding:12, color:'#fff', backgroundColor:'rgba('+postDetail.map((item) => item.color ? item.color : '192, 0, 32'+')'), bottom:60, right:10, fontSize:12, borderRadius:20, fontWeight:'bold'}}>Katalog Görünümü</a>
        */
      }
        

                {
                    productListVisible ? <Loading /> :
                    post.map((post, i) => {
                        return(
                            <ProductItems 
                                title={post.title} 
                                img={post.cover_img ? Services.apiServerUrl("languageShort")+post.cover_img : imageLists.productNotFound} 
                                iconBgColor={postDetail.map((item) => item.color ? item.color : '192, 0, 32')} 
                                iconColor={postDetail.map((item) => item.color ? item.color : '192, 0, 32')} 
                                url={"/productDetail/"+post.url} />
                        );
                    })
                }

                <BottomBarMenu />

            </div>
        </div>
    );
}


function ProductItems({title, img, iconBgColor, iconColor, url}){
    return(
        <Link to={url}>
            <div className="productItemContainer">
                <div className="productItems">
                    <div className="productImg">
                        <img src={img} alt="" />
                    </div>
                    <strong>{title}</strong>
                </div>

                <div className="productItemsArrow" style={{backgroundColor:iconBgColor ? 'rgba('+iconBgColor+', 0.1)' : '#fff'}}> 
                    <FaArrowRight fontSize={12} style={{color:iconColor ? 'rgba('+iconColor+')' : '#fff'}} /> 
                </div>
            </div>
        </Link>
    );
}


export default Products;