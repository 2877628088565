import React from "react";


function MainProductList({title, img, url, label}){
    return(
        <a href={url} className="mainProductItems">
            <div className="display-flex flex-column">
                <div className="mainProductImg display-flex flex-column align-items-center p-10">
                    {label ? <div className="new-label">{label}</div> : ""}
                    <img src={img} alt={title} />
                </div>
                <strong className="font-size-12 line-height-14 font-normal text-gray">{title}</strong>
            </div>
        </a>
    );
}



export default MainProductList;