import React from "react";
import BackNavigation from "../../components/backNavigation/backNavigation";
import BottomBarMenu from "../../layout/general/BottomBarMenu";
import CataloguesBrochuresItems from "../../layout/media/CataloguesBrochuresItems";
import Services from "../../services/Services";
import Loading from "../../components/loading/Loading";
import TranslateData from "../../services/TranslateData";
import imageLists from "../../contants/imageLists";

function Brochures(){

    const [brochuresPost, setBrochuresPost] = React.useState([]);
    const [visible, setVisible] = React.useState(true);

    const brochuresList = async () => {
        try {
             await Services.getList(Services.apiServerUrl("languageShort")+'api/data/brochures/?languageID='+sessionStorage.getItem("language")).then((response) =>  {
                setBrochuresPost(response.data);
             });
             setVisible(false);
        } catch (error) {
         console.log(error.message);
        }
     }

     React.useEffect(() => {
        brochuresList()
     }, []);


    if(visible) return <Loading />

    return(
        <>
            <BackNavigation title={TranslateData.Word("languageShort")[0]["brochures"]} />




            <div className="p-10 pt-20 line-height-24">
                
                <div className="display-flex flex-column align-items-center">
                    <div className="display-flex flex-wrap justify-content-center">

                        {
                            brochuresPost.map((item, cataloguesKey) => {
                                return(
                                    <CataloguesBrochuresItems 
                                        key={cataloguesKey}
                                        title={item.title} 
                                        img={item.cover_url ? Services.apiServerUrl("languageShort")+item.cover_url : imageLists.generalNotFound} 
                                        url={"/DocumentPreview/?url="+item.img_url}
                                    />
                                );
                            })
                        }
                    
                    
                    </div>
                </div>

            <BottomBarMenu />

            </div>
        </>
    );
}



export default Brochures;