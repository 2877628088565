import React, { useState, useEffect } from "react";
import BackNavigation from "../../components/backNavigation/backNavigation";
import BottomBarMenu from "../../layout/general/BottomBarMenu";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import Services from "../../services/Services";
import Loading from "../../components/loading/Loading";
import TranslateData from "../../services/TranslateData";
import imageLists from "../../contants/imageLists";

function Search(){

    const [productList, setProductList] = useState([])
    const [searchItem, setSearchItem] = useState('')

    const [filterProduct, setFilterProduct] = useState([])
    const [visible, setVisible] = React.useState(true)


    useEffect(() => {
        fetch(Services.apiServerUrl("languageShort")+'api/data/products/all_products.php?languageID='+Services.sessionGet("language"))
          .then(response => response.json())
          .then(data => {
            setProductList(data)
            setFilterProduct(data)
            setVisible(false);
          })
          .catch(err => console.log(err))
      }, [])



    const handleInputChange = (e) => { 
        const searchTerm = e.target.value;
        setSearchItem(searchTerm)
    
        const filteredItems = productList.filter((user) =>
          user.title.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilterProduct(filteredItems);
      }


    
    return(
        <>
            <BackNavigation title={TranslateData.Word("languageShort")[0].search} bgColor={"232, 255, 252"} />

            <div className="searchContainer">
                <input 
                type="text" 
                name="searchInput" 
                value={searchItem}
                onChange={handleInputChange}
                autoFocus={true}
                placeholder={TranslateData.Word("languageShort")[0].search+"..."} 
                autoComplete="off"
                className="searchInput border-all-1 text-gray pt-20 pb-20 pl-15 pr-15 radius-all-6" />

            </div>

            <div className="p-10 pt-60 pb-400" style={{backgroundColor:'rgb(232, 255, 252)'}}>

               <strong className="font-size-18 font-bold mt-30 mb-20 display-block text-green">{TranslateData.Word("languageShort")[0].search_result} : </strong>
            
               {
                    visible ? <Loading /> :
                    filterProduct.map((item, key) => {
                        return(
                            <ProductItems 
                                key={key} 
                                title={item.title} 
                                img={item.cover_img ? Services.apiServerUrl("languageShort")+item.cover_img : imageLists.productNotFound} 
                                iconBgColor={"#E8FFFC"} 
                                iconColor={"#00B09B"} 
                                url={"/productDetail/"+item.url} />
                        );
                    })
                }

                <BottomBarMenu />

            </div>
        </>
    );
}


function ProductItems({title, img, iconBgColor, iconColor, url}){
    return(
        <Link to={url}>
            <div className="productItemContainer" style={{backgroundColor:'#fff', border:'1px solid #9AF3E9'}}>
                <div className="productItems">
                    <div className="productImg">
                        <img src={img} alt="" />
                    </div>
                    <strong>{title}</strong>
                </div>

                <div className="productItemsArrow" style={{backgroundColor:iconBgColor}}> 
                    <FaArrowRight fontSize={12} color={iconColor} />
                </div>
            </div>
        </Link>
    );
}


export default Search;