import React from "react";

function SolutionCategoriesItems({title, img, url}){
    return(
        <a href={url} className="solutionCategoriesItems">
            <div className="display-flex flex-column align-items-center p-10">
                <img src={img} alt={title} />
                <strong className="font-size-12 line-height-16 font-bold text-black mt-10">{title}</strong>
            </div>
        </a>
    );
}

export default SolutionCategoriesItems;