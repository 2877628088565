import React from "react";

function MainTitles({title, subTitle}){
    return(
        <div className="text-left mt-30 mb-20 display-flex flex-column">
            {subTitle ? <strong className="font-size-26 font-bold text-gray-dark-1 font-normal-400">{subTitle}</strong> : ""}
            <strong className="font-size-34 font-bold text-black" style={{marginTop:'-3px'}}>{title}</strong>
        </div>
    );
}

export default MainTitles;