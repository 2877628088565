import Routers from "./routers/Routers";

function App() {

  caches.keys().then((names) => {
      names.forEach((name) => {
          caches.delete(name);
      });
  });

  return (




    <>

    
      <Routers />
    </>
  );
}

export default App;
