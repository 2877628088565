import React from "react";
import { FaHome, FaPlayCircle } from "react-icons/fa";
import productIc from "../../assets/icons/bottom-menu/product-ic.svg";
import calculatorIc from "../../assets/icons/bottom-menu/calculator-ic.svg";
import menuIc from "../../assets/icons/bottom-menu/menu-ic.svg";
import { Link } from "react-router-dom";
import DrawerMenu from "../../components/drawerMenu/DrawerMenu";


function BottomBarMenu(){

    const [isDrawer, setIsDrawer] = React.useState(false)

    const toggleDrawer = () => {
        setIsDrawer((prevState) => !prevState)
    }

    return(
        <>

        <DrawerMenu open={isDrawer} onClose={toggleDrawer} />

            <div className="width-100 mb-90"></div>
            <div className="bottomBarMenu">
                <Link to={"/"}>
                    <FaHome fontSize={26} color="#848484" />
                </Link>

                <span className="bottomBarMenuLine"></span>

                <Link to={"/Categories"}>
                    <img src={productIc} alt="" />
                </Link>

                <span className="bottomBarMenuLine"></span>

                <Link onClick={toggleDrawer}>
                   <img src={menuIc} alt="" />
                </Link>

                <span className="bottomBarMenuLine"></span>

                <Link to={"/Videos"}>
                    <FaPlayCircle fontSize={24} color="#848484" />
                </Link>

                <span className="bottomBarMenuLine"></span>

                <Link to={"/Calculator"}>
                    <img src={calculatorIc} alt={""} />
                </Link>
                
            </div>
        </>
        
    );
}



export default BottomBarMenu;