import React from "react";
import BackNavigation from "../../components/backNavigation/backNavigation";
import BottomBarMenu from "../../layout/general/BottomBarMenu";
import MainBlogPostList from "../../layout/main/MainBlogPostList";
import Services from "../../services/Services";
import Loading from "../../components/loading/Loading";
import TranslateData from "../../services/TranslateData";
import imageLists from "../../contants/imageLists";

function Blogs(){

    const [blogListPost, setBlogListPost] = React.useState([]);
    const [visible, setVisible] = React.useState([]);

    const blogList = async () => {
        try {
            await Services.getList(Services.apiServerUrl("languageShort")+'api/data/blogs/?languageID='+sessionStorage.getItem("language")).then((response) => {
                setBlogListPost(response.data);
                setVisible(false);
            });
        } catch (error) {
            console.log(error.message);
        }
    }


    React.useEffect(() => {
        blogList();
    }, []);


    if(visible) return <Loading />

    return(
        <>
            <BackNavigation title={TranslateData.Word("languageShort")[0]["blog"]} />

            <div className="p-10 pt-20">
                {
                    blogListPost.map((item, blogKey) => {
                        return(
                            <MainBlogPostList 
                                key={blogKey} 
                                title={item.title} 
                                img={item.img_url ? Services.apiServerUrl("languageShort")+item.img_url : imageLists.generalNotFound} 
                                url={"/blogDetail/"+item.url} />
                        );
                    })
                }

            </div>

            <BottomBarMenu />

        </>
    );
}


export default Blogs;