function De(){
    return(
        [{
            // Menu
            "home":"START",
            "products":"PRODUKTE",
            "categories":"KATEGORIEN",
            "about":"AKFİX",
            "videos":"VIDEOS",
            "catalogues":"KATALOG",
            "brochures":"BROCHÜREN",
            "certificates":"ZERTIFIKATE",
            "galleries":"GALLERIE",
            "blog":"BLOG",
            "contact":"KONTAKT",

            // Home Translate
            "home_product" : "Produkte",
            "home_categories" : "Kategorien",
            "home_solutions" : "Solutions",
            "home_videos" : "Videos",
            "home_new_products" : "Neue Produkte",
            "home_blog" : "Blog",

            // General Translate
            "languages": "Sprachen",
            "search": "Search",
            "search_result": "Suchergebnisse",
            "back": "Back",
            "file_uploading": "File Uploading...",
            
            // Product Detail Translate
            "properties" : "Eigenschaften",
            "application_areas" : "Anwendungsbereiche",
            "packaging" : "Verpackung",
            "product_videos" : "Videos",
            "product_galleries" : "Gallerie",
            "product_brochures" : "Brochüren",
            "stock_code" : "Lagercode",
            "product_code" : "Produktcode",
            "type" : "Typ",
            "volume" : "Volumen",
            "boxqty" : "Kartonmenge",
        }]
        
    )
}


export default De;