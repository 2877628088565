import React from "react";
import BackNavigation from "../../components/backNavigation/backNavigation";
import BottomBarMenu from "../../layout/general/BottomBarMenu";
import Services from "../../services/Services";
import Loading from "../../components/loading/Loading";
import parse from "html-react-parser";
import TranslateData from "../../services/TranslateData";
import imageLists from "../../contants/imageLists";

function About(){


    const [companyInfoPost, setCompanyInfoPost] = React.useState();
    const [visible, setVisible] = React.useState(true);



    const companyData = async () => {
        try {
         await Services.getList(Services.apiServerUrl("languageShort")+'api/data/company/?langParams='+sessionStorage.getItem["language"]).then((response) =>  {
            setCompanyInfoPost(response.data);
         });
        setVisible(false);
        } catch (error) {
            console.log(error.message);
        }
     }


    React.useEffect(() => {
        companyData();
    }, []);


    
    if(visible) return <Loading /> 


    return(
        <>
            <BackNavigation title={TranslateData.Word("languageShort")[0]["about"]} />

            <div className="aboutContainer p-10 pt-20">
            
                <div className="aboutBanner">
                    <img src={companyInfoPost["about_img_url"] ? Services.apiServerUrl("languageShort")+companyInfoPost["about_img_url"] : imageLists.generalNotFound} alt="" />
                </div>

                <strong className="aboutTitle">{companyInfoPost["company_name"]}</strong>

                {parse(companyInfoPost["about_description"])}


                <BottomBarMenu />


            </div>
        </>
    );
}


export default About;