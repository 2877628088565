function Bg(){
    return(
        [{

            // Menu
            "home":"ДОМА",
            "products":"ПРОДУКТИ",
            "categories":"КАТЕГОРИИ",
            "about":"AKFİX",
            "videos":"ВИДЕО",
            "catalogues":"КАТАЛОГ",
            "brochures":"БРОШУРИ",
            "certificates":"СЕРТИФИКАТИ",
            "galleries":"ГАЛЕРИЯ",
            "blog":"BLOG",
            "contact":"КОНТАКТИ",

            // Home Translate
            "home_product" : "Продукт",
            "home_categories" : "Категории",
            "home_solutions" : "Решения",
            "home_videos" : "Видео",
            "home_new_products" : "Нови продукти",
            "home_blog" : "Blog",

            // General Translate
            "languages": "Езици",
            "search": "Търсене",
            "search_result": "Резултат от търсенето",
            "back": "обратно",
            "file_uploading": "File Uploading...",
            
            // Product Detail Translate
            "properties" : "Свойства",
            "application_areas" : "Области на приложения",
            "packaging" : "Опаковка",
            "product_videos" : "Видео",
            "product_galleries" : "Галерия",
            "product_brochures" : "Брошури",
            "stock_code" : "Индивидуален код",
            "product_code" : "Продуктов код",
            "type" : "Вид",
            "volume" : "Обем",
            "boxqty" : "Брой в кашон",

        }]
        
    )
}


export default Bg;