import React, { useRef } from "react";
import BackNavigation from "../../components/backNavigation/backNavigation";
import BottomBarMenu from "../../layout/general/BottomBarMenu";
import { FaExclamationTriangle } from "react-icons/fa";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import closeIc from "../../assets/icons/close.svg";



function Calculator(){


    const width = useRef(0);
    const depth = useRef(0);
    const length = useRef(0);
    const ppt = useRef(0);

    const [width_mm, setWidth_mm] = React.useState(0);
    const [depth_mm, setDepth_mm] = React.useState(0);
    const [length_mm, setLength_mm] = React.useState(0);
    const [ppt_mm, setPpt_mm] = React.useState(0);

    const [isOpenCalculatorModal, setIsOpenCalculatorModal] = React.useState(false);

    
    return(
        <>
            <BackNavigation title={"Joint Sealant Calculator"} />


            <Modal open={isOpenCalculatorModal} onClose={setIsOpenCalculatorModal} showCloseIcon={false}>
                <div className="modalClose" onClick={() => setIsOpenCalculatorModal(false)}>
                    <img src={closeIc} alt="" />
                </div>

                <div className="calculatorResultContainer">
                    {
                        width.current.value == "" || 
                        depth.current.value == "" || 
                        length.current.value == "" ||
                        ppt.current.value == "" ? 
                        <div className="calculatorWarningAlert"><FaExclamationTriangle fontSize={20} className="mr-10" /> 
                            Please Fill In The Fields! 
                        </div> :
                        <>
                            <span>You Must Buy</span>
                            <strong>
                            {
                                Math.ceil((width.current.value * depth.current.value * length.current.value) / ppt.current.value / 1000)
                            }
                            </strong>
                            <span>Pieces {ppt.current.value} Ml {ppt.current.value > 310 ? "Sousage" : "Cartridge"}</span>
                            <small>(Total Usage : {Math.ceil((width.current.value * depth.current.value * length.current.value) / 1000)} ml)</small>
                        </>
                    }
                </div>
            </Modal>


            <div className="p-10 pt-20">

                <div class="width-100 display-flex flex-column mb-10">

                    <div className="container-100 display-flex flex-column mb-20">
                        <span className="font-size-16 mb-10 text-black">Width (mm)</span>
                        <input  ref={width} type="number" id="width_mm" name="width_mm" placeholder="Width (mm)" className="container-100 border-all-1 text-gray-dark-1 border-color-dark p-15 radius-all-6" />
                    </div>

                    <div className="container-100 display-flex flex-column mb-20">
                        <span className="font-size-16 mb-10 text-black">Depth (mm)</span>
                        <input  ref={depth} type="number" id="depth_mm" name="depth_mm" placeholder="Depth (mm)" className="container-100 border-all-1 text-gray-dark-1 border-color-dark p-15 radius-all-6" />
                    </div>
               
           
                    <div className="container-100 display-flex flex-column mb-20">
                        <span className="font-size-16 mb-10 text-black">Length (mm)</span>
                        <input type="number" ref={length} name="length_mm" id="length_mm" placeholder="Length (mm)" className="container-100 border-all-1 text-gray-dark-1 border-color-dark p-15 radius-all-6" />
                    </div>

                    <div className="container-100 display-flex flex-column mb-20">
                        <span className="font-size-16 mb-10 text-black">Product Packaging Type</span>
                        <select ref={ppt} id="ppt_mm" name="ppt_mm" className="width-100 border-all-1 text-gray-dark-1 border-color-dark p-15 radius-all-6">
                            <option value="200">200 ml Cartridge</option>
                            <option value="250">250 ml Cartridge</option>
                            <option value="280">280 ml Cartridge</option>
                            <option value="290">290 ml Cartridge</option>
                            <option value="300">300 ml Cartridge</option>
                            <option value="310">310 ml Cartridge</option>
                            <option value="400">400 ml Sousage</option>
                            <option value="600">600 ml Sousage</option>
                        </select>
                    </div>

                    <button onClick={() => setIsOpenCalculatorModal(true)} className="bg-green text-white text-center font-size-18 font-bold radius-all-8 p-20 mt-10">Calculate</button>

                </div>


                <BottomBarMenu />

            </div>
        </>
    );
}


export default Calculator;