import React from "react";
import BackNavigation from "../../components/backNavigation/backNavigation";
import BottomBarMenu from "../../layout/general/BottomBarMenu";
import addresIc from "../../assets/icons/contact/address.svg";
import phoneIc from "../../assets/icons/contact/phone.svg";
import faxIc from "../../assets/icons/contact/fax.svg";
import emailIc from "../../assets/icons/contact/email.svg";
import facebookIc from "../../assets/icons/social-media/facebook.svg";
import xIc from "../../assets/icons/social-media/x.svg";
import instagramIc from "../../assets/icons/social-media/instagram.svg";
import vimeoIc from "../../assets/icons/social-media/vimeo.svg";
import youtubeIc from "../../assets/icons/social-media/youtube.svg";
import Services from "../../services/Services";
import Loading from "../../components/loading/Loading";
import TranslateData from "../../services/TranslateData";


function Contact(){


    const [contactPost, setContactPost] = React.useState([]);
    const [visible, setVisible] = React.useState(true);


    const contactData = async () => {
       try {
        await Services.getList(Services.apiServerUrl("languageShort")+'api/data/contact/?languageID='+sessionStorage.getItem("language")).then((response) =>  {
            setContactPost(response.data);
            setVisible(false);
        });
       } catch (error) {
        console.log(error.message);
       }
    }



    React.useEffect(() => {
        contactData();
    }, [])


    return(

        visible ? <Loading /> :

        <>
        
            <BackNavigation title={TranslateData.Word("languageShort")[0]["contact"]} bgColor={'#fff'} />

            <div className="display-flex flex-column p-10 pt-20">

            <iframe title={"Contact"} src={"https://www.google.com/maps/embed?pb="+contactPost["maps"]} 
                className="maps"
                frameborder="0" 
                style={{"border": 0}} 
                allowfullscreen="" 
                aria-hidden="false" 
                tabindex="0"
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
            ></iframe>

            <div className="display-flex flex-column mt-30">

                <div className="display-flex flex-row align-items-center mt-10 mb-10">
                    <div className="contact-icon mr-20">
                        <img src={addresIc} alt="" />
                    </div>
                    <div className="display-flex flex-column">
                        <strong className="font-size-18 font-bold text-gray mb-5">{contactPost["title"]}</strong>
                        <span className="font-size-14 line-height-22 text-gray">{contactPost["address"]}</span>
                    </div>
                </div>

                <div className="display-flex flex-row align-items-center mt-10 mb-10">
                    <div className="contact-icon mr-20">
                        <img src={phoneIc} alt="" />
                    </div>
                    <div className="display-flex flex-column">
                        <strong className="font-size-18 font-bold text-gray mb-5">{contactPost["phone_title_1"]}</strong>
                        <span className="font-size-14 line-height-20 text-gray">{contactPost["phone_1"]}</span>
                    </div>
                </div>

                <div className="display-flex flex-row align-items-center mt-10 mb-10">
                    <div className="contact-icon mr-20">
                        <img src={faxIc} alt="" />
                    </div>
                    <div className="display-flex flex-column">
                        <strong className="font-size-18 font-bold text-gray mb-5">Fax</strong>
                        <span className="font-size-14 line-height-20 text-gray">{contactPost["fax"]}</span>
                    </div>
                </div>

                <div className="display-flex flex-row align-items-center mt-10 mb-10">
                    <div className="contact-icon mr-20">
                        <img src={emailIc} alt="" />
                    </div>
                    <div className="display-flex flex-column">
                        <strong className="font-size-18 font-bold text-gray mb-5">{contactPost["email_title_1"]} <span className="font-size-14 line-height-20 text-gray">{contactPost["email_1"]}</span></strong>
                        <strong className="font-size-18 font-bold text-gray mb-5">{contactPost["email_title_2"]} <span className="font-size-14 line-height-20 text-gray">{contactPost["email_2"]}</span></strong>
                    </div>
                </div>


                <div className="social-media-area mt-30">
                    {
                        contactPost["facebook_url"] === "" ? "" : 
                        <a href={contactPost["facebook_url"]} className="mr-15" target={"_blank"} rel={"noreferrer"}>
                            <img src={facebookIc} alt="" />
                        </a>
                    }

                    {   
                        contactPost["twitter_url"] === "" ? "" : 
                        <a href={contactPost["twitter_url"]} className="mr-15" target={"_blank"} rel={"noreferrer"}>
                            <img src={xIc} alt="" />
                        </a>
                    }


                    {   
                        contactPost["instagram_url"] === "" ? "" : 
                        <a href={contactPost["instagram_url"]} className="mr-15" target={"_blank"} rel={"noreferrer"}>
                            <img src={instagramIc} alt="" />
                        </a>
                    }

                    {   
                        contactPost["vimeo_url"] === "" ? "" : 
                        <a href={contactPost["vimeo_url"]} className="mr-15" target={"_blank"} rel={"noreferrer"}>
                            <img src={vimeoIc} alt="" />
                        </a>
                    }
                    
                    {   
                        contactPost["youtube_url"] === "" ? "" : 
                        <a href={contactPost["youtube_url"]} className="mr-15" target={"_blank"} rel={"noreferrer"}>
                            <img src={youtubeIc} alt="" />
                        </a>
                    }
                </div>

            </div>

            
            <BottomBarMenu />


            </div>
        </>
    );
}


export default Contact;