
import Tr from "../services/translate/Tr";
import En from "../services/translate/En";
import Es from "../services/translate/Es";
import Fr from "../services/translate/Fr";
import Bg from "../services/translate/Bg";
import Ru from "../services/translate/Ru";
import It from "../services/translate/It";
import Pl from "../services/translate/Pl";
import Rs from "../services/translate/Rs";
import Fi from "../services/translate/Fi";
import De from "../services/translate/De";
import Usa from "../services/translate/Usa";

class TranslateData{

    Word(name){

        if(sessionStorage.getItem(name) === "tr"){ return Tr(); }
        else if(sessionStorage.getItem(name) === "en"){ return En(); }
        else if(sessionStorage.getItem(name) === "fr"){ return Fr(); }
        else if(sessionStorage.getItem(name) === "es"){ return Es(); }
        else if(sessionStorage.getItem(name) === "bg"){ return Bg(); }
        else if(sessionStorage.getItem(name) === "ru"){ return Ru(); }
        else if(sessionStorage.getItem(name) === "it"){ return It(); }
        else if(sessionStorage.getItem(name) === "pl"){ return Pl(); }
        else if(sessionStorage.getItem(name) === "rs"){ return Rs(); }
        else if(sessionStorage.getItem(name) === "fi"){ return Fi(); }
        else if(sessionStorage.getItem(name) === "de"){ return De(); }
        else if(sessionStorage.getItem(name) === "usa"){ return Usa(); }
        else{ return En(); }
        
    }

}

export default TranslateData = new TranslateData();